import {
  GetServerSideProps,
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
  NextPage,
} from 'next';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import ConfigurationService from '../helpers/services/configuration.service';
import { useAppSelector } from '../lib/hooks';
import { useCityContext } from '../provider/CityProvider';
import { AppTheme } from '../types/constants';
import { Locales } from '../types/enums';
import { PushDataToGTM } from '../helpers/utilities/gtm';
import {
  GTMEvents,
  GTMSubEvents,
  PageCategories,
  PageNames,
} from '../types/gtm';

const RenderOnViewportEntry = dynamic(
  () => import('../components/RenderOnViewPortEntry/RenderOnViewportEntry'),
  {
    ssr: false,
  }
);
const ImageCarousel = dynamic(
  () => import('../components/home/ImageCarousel'),
  {
    ssr: true,
  }
);

const SearchWidget = dynamic(() => import('../components/home/SearchWidget'), {
  ssr: false,
});

const LatestPreOwnedCars = dynamic(
  () => import('../components/home/LatestPreOwnedCars'),
  {
    ssr: false,
  }
);

const PopularNewCars = dynamic(
  () => import('../components/home/PopularNewCars'),
  {
    ssr: false,
  }
);

const ExclusiveCars = dynamic(
  () => import('../components/home/ExclusiveDeal'),
  {
    ssr: false,
  }
);

const TopSearchedUsedCars = dynamic(
  () => import('../components/home/TopSearchedUsedCars'),
  {
    ssr: false,
  }
);

const CheckValuation = dynamic(
  () => import('../components/home/CheckValuation'),
  {
    ssr: false,
  }
);

const CarServices = dynamic(() => import('../components/home/CarServices'), {
  ssr: false,
});

const DiscountedNewCarsBudget = dynamic(
  () => import('../components/home/DiscountedNewCarsBudget'),
  {
    ssr: false,
  }
);

const ExploreByLocation = dynamic(
  () => import('../components/home/ExploreByLocation'),
  {
    ssr: false,
  }
);

const ExploreByBodyType = dynamic(
  () => import('../components/home/ExploreByBodyType'),
  {
    ssr: false,
  }
);

const EmiCalculator = dynamic(
  () => import('../components/home/EmiCalculator'),
  {
    ssr: false,
  }
);

const SelectCity = dynamic(() => import('../components/home/SelectCity'), {
  ssr: false,
});

const Home: NextPage = (
  props: InferGetServerSidePropsType<typeof getServerSideProps>
) => {
  const { i18n } = useTranslation();
  const router = useRouter();
  const [isLoadingBannerList, setIsLoadingBannerList] = useState(false);
  const { selectCity, cities, setSelectCity } = useCityContext();
  const [openModal, setOpenModal] = useState(false);
  const homeUserdCarList = useAppSelector(({ home }) => home);

  useEffect(() => {
    if (selectCity && selectCity.CityKey && router.isReady) {
      PushDataToGTM(GTMEvents.DataLayerUpdate, {
        eventCategory: GTMSubEvents.PageView,
        pageCategory: PageCategories.Home_page,
        pageName: PageNames.Home_page,
        originalLocation: window?.location.href,
        pageTitle: PageNames.Home_page,
        city: selectCity.CityKey.toUpperCase(),
        url: router.asPath,
        source: isMobile ? 'MOBILE' : 'WEB',
      });
    }
  }, [selectCity, router.isReady]);

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'GoGo Motor',
    url: 'https://www.gogomotor.com',
    logo: 'https://po-cdn-prod.azureedge.net/po-assets-prod/CMS/Common/gogo-motors.svg',
    sameAs: [
      'https://www.youtube.com/channel/UCgTpgQ321gcri3QKrfLf91A',
      'https://www.linkedin.com/company/gogomotorsa',
      'https://www.facebook.com/GoGoMotorSA',
      'https://twitter.com/GoGoMotorSA',
      'https://www.instagram.com/gogomotorsa',
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+966-8002440258',
      contactType: 'Customer Service',
      email: 'customercare@gogomotor.com',
      availableLanguage: ['Arabic', 'English'],
      hoursAvailable: {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday'],
        opens: '09:00',
        closes: '18:00',
      },
    },
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://www.gogomotor.com/en/search?q={search_term_string}',
      'query-input': 'required name=search_term_string',
    },
    hasPart: [
      {
        '@type': 'WebPage',
        url: 'https://www.gogomotor.com/en',
        name: 'GoGo Motor English Homepage',
        inLanguage: 'en',
        isPartOf: {
          '@type': 'WebSite',
          name: 'GoGo Motor',
          url: 'https://www.gogomotor.com',
        },
      },
      {
        '@type': 'WebPage',
        url: 'https://www.gogomotor.com',
        name: 'GoGo Motor Arabic Homepage',
        inLanguage: 'ar',
        isPartOf: {
          '@type': 'WebSite',
          name: 'GoGo Motor',
          url: 'https://www.gogomotor.com',
        },
      },
    ],
    app: [
      {
        '@type': 'MobileApplication',
        name: 'GoGo Motor App on Google Play',
        operatingSystem: 'ANDROID',
        applicationCategory: 'http://schema.org/ShoppingApplication',
        url: 'https://play.google.com/store/apps/details?id=com.petromin.gogomotor&pli=1',
      },
      {
        '@type': 'MobileApplication',
        name: 'GoGo Motor App on Apple Store',
        operatingSystem: 'iOS',
        applicationCategory: 'http://schema.org/ShoppingApplication',
        url: 'https://apps.apple.com/sa/app/gogo-motor/id1665705551',
      },
      {
        '@type': 'MobileApplication',
        name: 'GoGo Motor App on Huawei AppGallery',
        operatingSystem: 'HarmonyOS',
        applicationCategory: 'http://schema.org/ShoppingApplication',
        url: 'https://appgallery.huawei.com/app/C107741441',
      },
    ],
  };

  return (
    <>
      <Head>
        <title>GoGo Motor Homepage</title>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData, null, 2),
          }}
        />
      </Head>

      <div className="gogo-home-page">
        {/* Banner Section */}
        <div className="mb-8 min-h-[16.92rem] md:min-h-[35.66rem]">
          <ImageCarousel
            slides={props.sliders || []}
            direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            isLoading={isLoadingBannerList}
          />
        </div>

        <div className="min-h-[auto] lg:min-h-[24.83rem] md:mb-[16px]">
          <SearchWidget direction={i18n.language === 'ar' ? 'rtl' : 'ltr'} />
        </div>

        {/* In demand new cars */}
        <RenderOnViewportEntry
          threshold={0.3}
          priority="4"
          style={{ minHeight: isMobile ? '23.33rem' : '23.33rem' }}
        >
          <PopularNewCars />
        </RenderOnViewportEntry>

        {/* Pre Owned Cars */}
        <RenderOnViewportEntry
          threshold={0.2}
          priority="3"
          style={{
            minHeight: isMobile
              ? 'auto'
              : homeUserdCarList?.list?.length
              ? '32.25rem'
              : 0,
          }}
        >
          <LatestPreOwnedCars />
        </RenderOnViewportEntry>

        {/* Exclusive Car Deals */}
        <RenderOnViewportEntry
          threshold={0.3}
          priority="5"
          style={{ minHeight: isMobile ? 'auto' : '31.23rem' }}
        >
          <ExclusiveCars />
        </RenderOnViewportEntry>

        {/* Top searched used cars */}
        {homeUserdCarList?.list?.length > 0 && (
          <RenderOnViewportEntry
            threshold={0.3}
            priority="6"
            style={{
              minHeight: isMobile
                ? 'auto'
                : homeUserdCarList?.list?.length &&
                  homeUserdCarList?.toplist?.length
                ? '47.5rem'
                : 0,
            }}
          >
            <TopSearchedUsedCars />
          </RenderOnViewportEntry>
        )}

        {/* Looking to sell your car */}
        <RenderOnViewportEntry
          threshold={0.3}
          priority="7"
          style={{ minHeight: isMobile ? 'auto' : '13.75rem' }}
        >
          <CheckValuation />
        </RenderOnViewportEntry>

        {/* gogo motor car services */}
        <RenderOnViewportEntry
          threshold={0.3}
          priority="7"
          style={{ minHeight: isMobile ? 'auto' : '63.33rem' }}
        >
          <CarServices />
        </RenderOnViewportEntry>

        {/* Discounted new cars for your budget */}
        <RenderOnViewportEntry
          threshold={0.3}
          priority="8"
          style={{ minHeight: isMobile ? 'auto' : '41.91rem' }}
        >
          <DiscountedNewCarsBudget />
        </RenderOnViewportEntry>

        {/* Explore by location */}
        <RenderOnViewportEntry
          threshold={0.3}
          priority="9"
          style={{ minHeight: isMobile ? 'auto' : '41.91rem' }}
        >
          <ExploreByLocation />
        </RenderOnViewportEntry>

        {/* Explore by body type */}
        <RenderOnViewportEntry
          threshold={0}
          priority="10"
          style={{ minHeight: isMobile ? 'auto' : '24.58rem' }}
        >
          <ExploreByBodyType />
        </RenderOnViewportEntry>

        {/* Know your buying power */}
        <RenderOnViewportEntry
          threshold={0.3}
          priority="10"
          style={{ minHeight: isMobile ? 'auto' : '20rem' }}
        >
          <EmiCalculator direction={i18n.language === 'ar' ? 'rtl' : 'ltr'} />
        </RenderOnViewportEntry>
      </div>

      {openModal && (
        <SelectCity
          cities={cities}
          show={openModal}
          onClose={(val: any) => {
            if (val) {
              setSelectCity(val);
              router.push(`/all-listings?city=${val?.CityKey}`);
              localStorage.setItem('city', JSON.stringify(val));
            }
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async ({
  locale,
}: GetServerSidePropsContext) => {
  let list: any = await ConfigurationService.getBannersData(locale, 'Homepage');
  return {
    props: {
      sliders: list,
      ...(await serverSideTranslations(locale || Locales.EN, ['common'])),
      applyTheme: AppTheme.V1,
      imageUrl: `${process.env.NEXT_PUBLIC_CMS_API_URL}${list[0]?.attributes?.Image?.data?.attributes?.url}`,
    },
  };
};
